var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: { pageRoot: true } }, [
    _vm.error
      ? _c("div", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
      : _vm.loading
      ? _c(
          "div",
          { staticClass: "loading" },
          [_c("ScaleLoader"), _vm._v(" Loading…")],
          1
        )
      : _c("div", [
          _c("div", { staticClass: "heading" }, [
            _vm._v("Manage User Account"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "bodySection" }, [
            _c("p", [
              _vm._v("\n\t\t\t\tPrimary email: " + _vm._s(_vm.userEmail) + " "),
              _c("input", {
                attrs: {
                  type: "button",
                  value: "change",
                  disabled: _vm.isUpdating,
                },
                on: {
                  click: function ($event) {
                    return _vm.changePrimaryEmail()
                  },
                },
              }),
              _vm._v(" "),
              _c("input", {
                attrs: {
                  type: "button",
                  value: "test",
                  disabled: _vm.isUpdating,
                },
                on: {
                  click: function ($event) {
                    return _vm.testEmail(_vm.userEmail)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _vm.allowPasswordAuth
              ? _c(
                  "p",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { name: "changePassword" } } },
                      [_vm._v("Change Password")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("p", [
              _c("input", {
                staticClass: "logoutButton",
                attrs: {
                  type: "button",
                  value: "Log Out",
                  disabled: _vm.isUpdating,
                },
                on: { click: _vm.logoutClicked },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "heading", class: { highlight: _vm.createPasskey } },
            [
              _c("img", {
                staticClass: "headingIcon",
                attrs: {
                  src: _vm.appPath + "Images/key.png",
                  alt: "",
                  role: "presentation",
                },
              }),
              _vm._v("\n\t\t\tPasskeys\n\t\t"),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "bodySection",
              class: { highlight: _vm.createPasskey },
            },
            [
              _c("p"),
              _vm._l(_vm.passkeys, function (pk) {
                return _c(
                  "div",
                  { key: pk.Id, staticClass: "passkeyContainer" },
                  [
                    _c("input", {
                      staticClass: "passkeyDelete",
                      attrs: {
                        type: "button",
                        value: "delete",
                        disabled: _vm.isUpdating,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.deletePasskey(pk)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("div", [_vm._v("Id: " + _vm._s(pk.Id))]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "Created " +
                          _vm._s(_vm.getFuzzyTimeDistance(pk.RegDate))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "Last used " +
                          _vm._s(_vm.getFuzzyTimeDistance(pk.LastUsed))
                      ),
                    ]),
                  ]
                )
              }),
              _vm._v(" "),
              !_vm.passkeys || !_vm.passkeys.length
                ? _c("div", [
                    _vm._v(
                      "Your account does not have any Passkeys registered."
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.passkeySupported
                ? _c("div", { staticClass: "buttonBar" }, [
                    _c(
                      "button",
                      {
                        staticClass: "buttonBarButton",
                        attrs: { disabled: _vm.isUpdating },
                        on: { click: _vm.BeginCreatePasskey },
                      },
                      [
                        _c("img", {
                          staticClass: "btnIconNoHover",
                          attrs: {
                            src: _vm.appPath + "Images/key_add.png",
                            alt: "",
                            role: "presentation",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Create passkey")]),
                      ]
                    ),
                  ])
                : _c("div", [
                    _vm._v("This web browser does not support passkeys."),
                  ]),
              _vm._v(" "),
              _c("p"),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "heading" }, [
            _vm._v("Additional Email Addresses"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bodySection" },
            [
              _c("p"),
              _vm._l(_vm.additionalEmails, function (a) {
                return _c("div", { key: a }, [
                  _vm._v("\n\t\t\t\t\t" + _vm._s(a) + " "),
                  _c("input", {
                    attrs: {
                      type: "button",
                      value: "delete",
                      disabled: _vm.isUpdating,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.deleteEmail(a)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    attrs: {
                      type: "button",
                      value: "test",
                      disabled: _vm.isUpdating,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.testEmail(a)
                      },
                    },
                  }),
                ])
              }),
              _vm._v(" "),
              _c("p"),
              _vm._v(" "),
              _c("p", [
                _c("input", {
                  attrs: {
                    type: "button",
                    value: "Add Another Email Address",
                    disabled: _vm.isUpdating,
                  },
                  on: { click: _vm.addEmail },
                }),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "heading" }, [_vm._v("Email-To-SMS")]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "heading" }, [_vm._v("Active Sessions")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bodySection" },
            [_c("ActiveSessionList", { attrs: { allSessions: false } })],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "heading" }, [_vm._v("Login History")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bodySection" },
            [_c("LoginRecordsTable")],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "heading" }, [_vm._v("User Activity Logs")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bodySection" },
            [_c("UserActivityTable")],
            1
          ),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c(
        "a",
        {
          attrs: {
            href: "https://www.digitaltrends.com/mobile/how-to-send-a-text-from-your-email-account/",
          },
        },
        [
          _vm._v(
            "https://www.digitaltrends.com/mobile/how-to-send-a-text-from-your-email-account/"
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bodySection" }, [
      _c("table", { staticClass: "emailToSmsTable" }, [
        _c("thead", [
          _c("tr", [
            _c("th", { attrs: { align: "left" } }, [_vm._v("Carrier")]),
            _vm._v(" "),
            _c("th", { attrs: { align: "center" } }, [
              _vm._v("SMS Gateway Domain"),
            ]),
            _vm._v(" "),
            _c("th", { attrs: { align: "center" } }, [
              _vm._v("MMS Gateway Domain"),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("tbody", [
          _c("tr", [
            _c("td", { attrs: { align: "left" } }, [
              _c("strong", [_vm._v("AT&T")]),
            ]),
            _vm._v(" "),
            _c("td", { attrs: { align: "center" } }, [
              _vm._v("number@txt.att.net"),
            ]),
            _vm._v(" "),
            _c("td", { attrs: { align: "center" } }, [
              _vm._v("number@mms.att.net"),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { attrs: { align: "left" } }, [
              _c("strong", [_vm._v("Boost Mobile")]),
            ]),
            _vm._v(" "),
            _c("td", { attrs: { align: "center" } }, [
              _vm._v("number@sms.myboostmobile.com"),
            ]),
            _vm._v(" "),
            _c("td", { attrs: { align: "center" } }, [
              _vm._v("number@myboostmobile.com"),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { attrs: { align: "left" } }, [
              _c("strong", [_vm._v("Cricket Wireless")]),
            ]),
            _vm._v(" "),
            _c("td", { attrs: { align: "center" } }, [
              _vm._v("number@mms.cricketwireless.net"),
            ]),
            _vm._v(" "),
            _c("td", { attrs: { align: "center" } }, [
              _vm._v("number@mms.cricketwireless.net"),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { attrs: { align: "left" } }, [
              _c("strong", [_vm._v("Google Project Fi")]),
            ]),
            _vm._v(" "),
            _c("td", { attrs: { align: "center" } }, [
              _vm._v("number@msg.fi.google.com"),
            ]),
            _vm._v(" "),
            _c("td", { attrs: { align: "center" } }, [
              _vm._v("number@msg.fi.google.com"),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { attrs: { align: "left" } }, [
              _c("strong", [_vm._v("Republic Wireless")]),
            ]),
            _vm._v(" "),
            _c("td", { attrs: { align: "center" } }, [
              _vm._v("number@text.republicwireless.com"),
            ]),
            _vm._v(" "),
            _c("td", { attrs: { align: "center" } }, [_vm._v("None")]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { attrs: { align: "left" } }, [
              _c("strong", [_vm._v("Sprint")]),
            ]),
            _vm._v(" "),
            _c("td", { attrs: { align: "center" } }, [
              _vm._v("number@messaging.sprintpcs.com"),
            ]),
            _vm._v(" "),
            _c("td", { attrs: { align: "center" } }, [
              _vm._v("number@pm.sprint.com"),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { attrs: { align: "left" } }, [
              _c("strong", [_vm._v("Straight Talk")]),
            ]),
            _vm._v(" "),
            _c("td", { attrs: { align: "center" } }, [
              _vm._v("number@vtext.com"),
            ]),
            _vm._v(" "),
            _c("td", { attrs: { align: "center" } }, [
              _vm._v("number@mypixmessages.com"),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { attrs: { align: "left" } }, [
              _c("strong", [_vm._v("T-Mobile")]),
            ]),
            _vm._v(" "),
            _c("td", { attrs: { align: "center" } }, [
              _vm._v("number@tmomail.net"),
            ]),
            _vm._v(" "),
            _c("td", { attrs: { align: "center" } }, [
              _vm._v("number@tmomail.net"),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { attrs: { align: "left" } }, [
              _c("strong", [_vm._v("Ting")]),
            ]),
            _vm._v(" "),
            _c("td", { attrs: { align: "center" } }, [
              _vm._v("number@message.ting.com"),
            ]),
            _vm._v(" "),
            _c("td", { attrs: { align: "center" } }, [_vm._v("None")]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { attrs: { align: "left" } }, [
              _c("strong", [_vm._v("Tracfone")]),
            ]),
            _vm._v(" "),
            _c("td", { attrs: { align: "center" } }, [
              _vm._v("Depends on underlying carrier"),
            ]),
            _vm._v(" "),
            _c("td", { attrs: { align: "center" } }, [
              _vm._v("number@mmst5.tracfone.com"),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { attrs: { align: "left" } }, [
              _c("strong", [_vm._v("U.S. Cellular")]),
            ]),
            _vm._v(" "),
            _c("td", { attrs: { align: "center" } }, [
              _vm._v("number@email.uscc.net"),
            ]),
            _vm._v(" "),
            _c("td", { attrs: { align: "center" } }, [
              _vm._v("number@mms.uscc.net"),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { attrs: { align: "left" } }, [
              _c("strong", [_vm._v("Verizon")]),
            ]),
            _vm._v(" "),
            _c("td", { attrs: { align: "center" } }, [
              _vm._v("number@vtext.com"),
            ]),
            _vm._v(" "),
            _c("td", { attrs: { align: "center" } }, [
              _vm._v("number@vzwpix.com"),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { attrs: { align: "left" } }, [
              _c("strong", [_vm._v("Virgin Mobile")]),
            ]),
            _vm._v(" "),
            _c("td", { attrs: { align: "center" } }, [
              _vm._v("number@vmobl.com"),
            ]),
            _vm._v(" "),
            _c("td", { attrs: { align: "center" } }, [
              _vm._v("number@vmpix.com"),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }