import { ProgressDialog, ModalMessageDialog } from 'appRoot/scripts/ModalDialog';
import { PasskeyRegisterChallenge, PasskeyRegister, PasskeyLoginChallenge, PasskeyLogin } from 'appRoot/api/PasskeyData';

let passkeyAbortController = null;
function cancelPasskeyOperation()
{
	if (passkeyAbortController)
	{
		passkeyAbortController.abort();
		passkeyAbortController = null;
	}
}
function getPasskeyAbortSignal()
{
	if (!passkeyAbortController)
		passkeyAbortController = new AbortController();
	return passkeyAbortController.signal;
}
export async function BeginCreatePasskey(isAtLogin)
{
	let progressDialog = ProgressDialog("Creating passkey…");
	try
	{
		let response = await PasskeyRegisterChallenge(isAtLogin);
		if (response.success)
		{
			let options = passkey_parseCreationOptionsFromJSON(response.optionsJson);
			cancelPasskeyOperation();
			let credential = await navigator.credentials.create({
				publicKey: options,
				signal: getPasskeyAbortSignal(),
			});
			let serverCredential = passkey_ObjectConvertToServerFormat(credential);
			response = await PasskeyRegister(arrayBufferToBase64URL(options.challenge), serverCredential);
			if (response.success)
			{
				ModalMessageDialog("Passkey creation was successful.", "Passkey Created");
				return true;
			}
			else
				ModalMessageDialog(response.error, "Passkey Creation");
		}
		else
			ModalMessageDialog(response.error, "Passkey Creation");
	}
	catch (ex)
	{
		if (ex.name === 'AbortError')
			return;
		if (ex.name === 'NotAllowedError')
			return; // User cancelled
		if (ex.name === 'OperationError')
			return; // User cancelled
		if (ex.name === 'InvalidStateError')
		{
			ModalMessageDialog("This passkey was already registered with the server.", "Passkey Created");
			return;
		}
		ModalMessageDialog("Passkey Creation Failed: " + ex.name + ": " + ex.message, "Passkey Creation");
	}
	finally
	{
		progressDialog.close();
	}
	return false;
}

export async function LoginWithPasskey(user, isConditional)
{
	let progressDialog = null;
	if (!isConditional)
		progressDialog = ProgressDialog("Passkey login…");
	try
	{
		let response = await PasskeyLoginChallenge(isConditional ? null : user);

		if (response.success)
		{
			const options = passkey_parseRequestOptionsFromJSON(response.optionsJson);
			if (!isConditional && (!options.allowCredentials || !options.allowCredentials.length))
			{
				toaster.info("No passkeys", "This account does not have any passkeys registered.");
				return { tryPassword: true, noPasskeysRegistered: true };
			}

			cancelPasskeyOperation();
			const credential = await navigator.credentials.get({
				publicKey: options,
				signal: getPasskeyAbortSignal(),
				mediation: isConditional ? "conditional" : undefined,
			});
			let serverCredential = passkey_ObjectConvertToServerFormat(credential);
			response = await PasskeyLogin(arrayBufferToBase64URL(options.challenge), serverCredential);
			if (response.success)
			{
				//if (response.appContext)
				//	window.appContext = response.appContext;

				if (response.message)
				{
					ModalMessageDialog(response.error, response.message);
					return { tryPassword: true };
				}

				if (response.authenticated)
				{
					return { authenticated: true, response: response };
				}
				else
				{
					ModalMessageDialog("Passkey login protocol failed without a message.", "Passkey Login");
					return { tryPassword: true };
				}
			}
			else
			{
				ModalMessageDialog(response.error, "Passkey Login");
				return { tryPassword: true };
			}
		}
		else
		{
			if (isConditional)
				toaster.error(response.error);
			else
				ModalMessageDialog(response.error, "Passkey Login");
			return { tryPassword: true };
		}
	}
	catch (ex)
	{
		if (ex.name === 'AbortError')
			return { tryPassword: false };
		if (ex.name === 'NotAllowedError')
			return { tryPassword: true }; // User cancelled
		if (ex.name === 'OperationError')
			return { tryPassword: true }; // User cancelled
		if (ex.message.indexOf("timed out") > -1)
		{
			console.log("Passkey login timed out");
			return { tryPassword: true };
		}
		console.error(ex);
		let str = "Passkey Login Failed: " + ex.name + ": " + ex.message;
		if (isConditional)
		{
			//if (ex.name === 'SecurityError')
				toaster.error(str);
		}
		else
			ModalMessageDialog(str, "Passkey Login");
		return { tryPassword: true };
	}
	finally
	{
		if (progressDialog)
			progressDialog.close();
	}
	return { tryPassword: true };
}

function base64ToArrayBuffer(base64)
{
	const bin = atob(base64);
	return Uint8Array.from(bin, c => c.charCodeAt(0));
}

function base64URLToArrayBuffer(base64URL)
{
	const base64 = base64URL.replaceAll('-', '+').replaceAll('_', '/');
	return base64ToArrayBuffer(base64);
}

export function arrayBufferToBase64URL(buffer)
{
	let bin = '';
	const bytes = new Uint8Array(buffer);
	const len = bytes.byteLength;

	for (let i = 0; i < len; i++)
	{
		bin += String.fromCharCode(bytes[i]);
	}
	return btoa(bin)
		.replaceAll('+', '-')
		.replaceAll('/', '_')
		.replaceAll('=', '');
}
/**
 * Returns a promise that resolves to true if this web browser supports Passkeys.
 */
export async function passkey_supportedAsync()
{
	try
	{
		if (window.PublicKeyCredential && PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable)
		{
			let result = await PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
			if (result === true)
				return true;
		}
	}
	catch (ex)
	{
	}
	return false;
}
/**
 * Returns a promise that resolves to true if this web browser supports WebAuthn conditional mediation (passkey autofill).
 */
export async function passkey_autofillSupportedAsync()
{
	try
	{
		if (window.PublicKeyCredential && PublicKeyCredential.isConditionalMediationAvailable)
		{
			let result = await PublicKeyCredential.isConditionalMediationAvailable();
			if (result === true)
				return true;
		}
	}
	catch (ex)
	{
	}
	return false;
}
/**
 * A substitute for the missing framework-provided deserializer.
 * @param {String} json JSON-encoded string containing a CreationOptions for webauthn (passkeys).
 * @returns CreationOptions object.
 */
function passkey_parseCreationOptionsFromJSON(json)
{
	// Parse the JSON string into an object
	let obj = JSON.parse(json);

	// Convert the object into a PublicKeyCredentialCreationOptions object
	let publicKeyCredentialCreationOptions = {
		challenge: base64URLToArrayBuffer(obj.challenge),
		rp: obj.rp,
		user: {
			id: base64URLToArrayBuffer(obj.user.id),
			name: obj.user.name,
			displayName: obj.user.displayName,
			icon: obj.user.icon
		},
		pubKeyCredParams: obj.pubKeyCredParams,
		timeout: obj.timeout,
		excludeCredentials: obj.excludeCredentials.map(cred => ({
			id: base64URLToArrayBuffer(cred.id),
			type: cred.type,
			transports: cred.transports
		})),
		authenticatorSelection: obj.authenticatorSelection,
		attestation: obj.attestation,
		extensions: obj.extensions
	};

	return publicKeyCredentialCreationOptions;
}
/**
 * A substitute for the missing framework-provided deserializer.
 * @param {String} json JSON-encoded string containing a CreationOptions for webauthn (passkeys).
 * @returns CreationOptions object.
 */
export function passkey_parseRequestOptionsFromJSON(json)
{
	// Parse the JSON string into an object
	let obj = JSON.parse(json);

	// Convert the base64Url strings into ArrayBuffers
	let options = {
		allowCredentials: obj.allowCredentials.map(cred => ({
			id: base64URLToArrayBuffer(cred.id),
			type: cred.type
		})),
		challenge: base64URLToArrayBuffer(obj.challenge),
		rpId: obj.rpId,
		timeout: obj.timeout,
		userVerification: obj.userVerification
	};

	return options;
}
/**
 * Converts the given PublicKeyCredential to a format that can be sent to the server as JSON as part of passkey creation.
 * @param {Object} credential The PublicKeyCredential to convert.
 * @returns A reformatted PublicKeyCredential where ArrayBuffers are now Base64URL strings.
 */
export function passkey_ObjectConvertToServerFormat(credential)
{
	if (!credential.response)
		throw new Error("Credential did not contain a response property.");

	if (credential.response.attestationObject)
	{
		// Assume this is an attestation object involved in passkey registration
		return {
			id: ConvertArrayBuffer(credential.id),
			rawId: ConvertArrayBuffer(credential.rawId),
			type: credential.type,
			response: {
				attestationObject: ConvertArrayBuffer(credential.response.attestationObject),
				clientDataJSON: ConvertArrayBuffer(credential.response.clientDataJSON),
			},
			extensions: credential.extensions
		};
	}
	else
	{
		// Assume this is an assertion object involved in passkey login
		return {
			id: ConvertArrayBuffer(credential.id),
			rawId: ConvertArrayBuffer(credential.rawId),
			authenticatorAttachment: credential.authenticatorAttachment,
			type: credential.type,
			response: {
				authenticatorData: ConvertArrayBuffer(credential.response.authenticatorData),
				clientDataJSON: ConvertArrayBuffer(credential.response.clientDataJSON),
				signature: ConvertArrayBuffer(credential.response.signature),
				userHandle: ConvertArrayBuffer(credential.response.userHandle),
			},
		};
	}
}
function ConvertArrayBuffer(object)
{
	if (typeof object === "undefined")
		return undefined;
	if (typeof object === "object")
		return arrayBufferToBase64URL(object);
	else if (typeof object === "string")
		return object;
	else
		throw new Error('ConvertArrayBuffer does not support argument of type "' + typeof object + '"');
}