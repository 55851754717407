import ExecAPI from 'appRoot/api/api';

export function PasskeyRegisterChallenge(isAtLogin)
{
	return ExecAPI("PasskeyCreate/PasskeyRegisterChallenge", { isAtLogin });
}
export function PasskeyRegister(challenge, attestationResponse)
{
	return ExecAPI("PasskeyCreate/PasskeyRegister", { challenge, attestationResponse });
}
export function PasskeyLoginChallenge(user)
{
	return ExecAPI("PasskeyAuth/PasskeyLoginChallenge", { user });
}
export function PasskeyLogin(challenge, assertionResponse)
{
	return ExecAPI("PasskeyAuth/PasskeyLogin", { challenge, assertionResponse });
}